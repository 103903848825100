import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import loginApp from './reducers/login';
import emailApp from './reducers/emails';
import blogApp from './reducers/blogs';
import editorApp from './reducers/editor';
import adsApp from './reducers/ads';
import demoApp from './reducers/demo';
import tableauApp from './reducers/tableau';
import productDescriptionsApp from './reducers/productDescriptions';
// import profileApp from './reducers/profiles';
import settingsApp from './reducers/Settings';
import Analyze from './components/Analyze/Analyze.slice';
import AnalyzeContent from './components/Analyze/Content/AnalyzeContent.slice';
import AnalyzePerformance from './components/Analyze/Performance/AnalyzePerformance.slice';
import OptimizeContent from './components/Optimize/Content/OptimizeContent.slice';
import AnalyzeTrending from './components/Analyze/Trending/AnalyzeTrending.slice';
import AnalyzeTopicsInsight from './components/Analyze/TopicsInsight/AnalyzeTopicsInsight.slice';
import AnalyzeTopicsRelated from './components/Analyze/TestTable/TopicsRelated.slice';
import EngagementWords from './components/EngagementWords/EngagementWords.slice';
import SidebarEditor from './components/common/writingEnvironment/RightSidebar/SidebarEditor.slice';
import Campaigns from './components/Campaigns/Campaigns.slice';
import Personas from './components/Persona/Personas.slice';
import MediaRanking from './components/MediaRanking/MediaRanking.slice';
import Layout from './_metronic/layout/Layout.slice';

import { authChecker, alphabetSorter, debugMiddleware } from './middleware';

const reducers = combineReducers({
  login: loginApp,
  email: emailApp,
  blog: blogApp,
  editor: editorApp,
  settings: settingsApp,
  demo: demoApp,
  tableau: tableauApp,
  ads: adsApp,
  productDescriptions: productDescriptionsApp,
  Analyze,
  AnalyzeContent,
  AnalyzePerformance,
  OptimizeContent,
  AnalyzeTrending,
  AnalyzeTopicsInsight,
  AnalyzeTopicsRelated,
  EngagementWords,
  SidebarEditor,
  Campaigns,
  Personas,
  MediaRanking,
  Layout,
});

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(
      thunkMiddleware,
      authChecker,
      alphabetSorter,
      debugMiddleware
      // logger,
    )
  )
);
let currentValue;
store.subscribe(() => {
  // const previousValue = currentValue;
  // currentValue = store.getState();
  // console.log(currentValue.SidebarEditor);
  /* console.log(
    currentValue.editor.regionSuggestionFeedback,
    currentValue.editor.optimizeFeedback
  ); */
});

export default store;
